.dataTables_empty {
  display: none;
}

.custom-card-title{
  margin-top: 10px;
}

.requiredtext{
  color: red;
}

.requiredtext{
  color: red;
}
.bg-navy{
  background-color: #001f3f !important;
}

.requirederror{
  color: red;
  display: inline-block;
  width: 100%;
  font-size: 15px;
}

.dropdown-menu{
  margin:0;
}
.fc-event-main
{
  font-size: 120%;
  font-weight: bold;
}


.dtprocessing{
  box-shadow: 0px 3px 5px 5px lightgrey;
  display: inline-block;
  width: 200px;
}

.fs13{
  font-size: 13px;
}

.p0{
  padding: 0px;
}

.p6{
  padding: 6px!important;
}

.m0{
  margin: 0px;
}

.mt5{
  margin-top: 5px;
}

.mr5{
  margin-right: 5px;
}

.mb5{
  margin-bottom: 5px;
}

.mb15{
  margin-bottom: 15px;
}

.plr5{
  padding-left: 5px;
  padding-right: 5px;
}

.tooltip{
  opacity :1!important;
}

.nowrap{
  white-space: nowrap;
}

.displayinline{
  display: inline;
}

.hreflink{
  color : blue;
  cursor:pointer;
}

.select2-container{
  width: 100%!important;
  min-height: 38px;
}

.select2-container--default .select2-selection--single{
  height: 38px!important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
  height: 36px!important;
}

.inline-flatpickr .form-control,
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none;
}

.customcheckboxlabel{
  margin-left: 5px;
  font-weight: 500!important;
}

.flashmessage-wrapper{
  margin-left:250px;
  background-color:#f4f6f9;
}

.sidebar-collapse .flashmessage-wrapper{
  margin-left:0;
}

.flash-message{
  margin: 0;
}

.fc-header-toolbar div:nth-of-type(3) .fc-button{
  margin: 0;
  border-radius: 0;
}



.form_overall {
  width: 100%;
}

.form_overall h3 {
  margin-bottom: 20px;
}

.form_overall .form-group {
  margin-bottom: 30px !important;
}

.form_overall .form-group label {
  font-weight: 500 !important;
  color: #000;
}

.custom_btn {
  text-align: right;
}

.form_overall h4:before {
  content: "\f0ac";
  font-family: 'Font Awesome 5 Free';
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin-right: 5px;
}

.bs-stepper-header {
  flex-flow: column wrap;
  align-items: flex-start !important;
}

.bs-stepper .card {
  padding: 20px 10px 20px 10px;
}

.bs-stepper-header .step {
  margin-left: -1px;
  width: 100%;
}

.bs-stepper-header button.step-trigger {
  width: 100%;
  justify-content: flex-start !important;
  padding: 10px 0 10px 20px !important;
  display: inline-block !important;
  text-align: left !important;
}

.step.active .bs-stepper-label {
  color: #007bff !important;
}

body, html {
/*  overflow-x: hidden;
  height:100% !important;*/
}

/* body:not(.layout-fixed) .main-sidebar .sidebar {
  overflow: hidden !important;
} */

.main-sidebar,
.main-sidebar::before {
  transition: margin-left .3s ease-in-out, width .3s ease-in-out;
  width: 190px !important;
}

@media (min-width:768px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    transition: margin-left .3s ease-in-out;
    margin-left: 189px;
  }
}

full-calendar .fc-header-toolbar.fc-toolbar {
  padding: 0;
  display: flex;
}

full-calendar .fc-header-toolbar.fc-toolbar h2.fc-toolbar-title {
  font-size: 20px;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:last-child {
  margin-right: 0;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:last-child button.fc-button.fc-button-primary {
  margin-left: 0 !important;
  border-radius: 0;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:last-child button.fc-button.fc-button-primary:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:last-child button.fc-button.fc-button-primary:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dataTables_wrapper {
  overflow-x: auto;
}

full-calendar .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk button.fc-button-primary {
  font-size: 14px;
  text-transform: capitalize;
}

.nav-pills + .tab-content {
  height: 610px;
  overflow-y: scroll;
}

.nav-pills + .tab-content::-webkit-scrollbar {
  width: 6px;
}

.nav-pills + .tab-content::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 28%);
  border-radius: 11px;
}

.nav-pills + .tab-content::-webkit-scrollbar-thumb {
  background-color: #2c3e50;
  border-radius: 10px;
}

#branch_2 {
  margin-top: 20px;
}

.nav-sidebar > li.nav-item, .nav-sidebar > li.nav-item > a {
  width: 100% !important;
}

.nav-sidebar > li.nav-item > a.nav-link {
  padding-left: 9px;
  margin-bottom: 0;
}

aside.sidebar-dark-primary {
  background-color: #3f6791;
  box-shadow: none !important;
}
.sidebar a
{
  color: white !important;
}
nav.main-header.navbar-expand {
  background-color: #008DC8;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  z-index: 1039;
}

aside.main-sidebar > a.brand-link {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 17px;
  background-color: #008DC8;
  border-bottom: none;
}

aside.main-sidebar > a.brand-link > span {
  color: #fff;
  font-weight: 500 !important;
}
app-patients-view .patient-dashboard
{
  padding: 0;
  line-height: 3;
}

app-patients-view .patient-dashboard .nav-item a.active
{
  background-color: #008DC8;
}
table.dataTable thead
{
  background-color: #008DC8;
  color: white;
}
table.dataTable{
  border-collapse: collapse !important;
}
nav.main-header li.nav-item > a {
  color: #fff !important;
}

span.info-box-icon.bg-info {
  background-color: #00a65a !important;
}

@media only screen and (max-width: 992px) {
  .bs-stepper-header {
    flex-flow: row;
    overflow-x: scroll;
  }

  #stepper1 .bs-stepper-header {
    flex-flow: row;
    overflow-x: scroll;
  }

  #stepper1 .bs-stepper-header button.step-trigger {
    padding: 7px 20px 7px 20px !important;
  }

  #stepper1 .bs-stepper-content {
    margin: 0 20px 0 20px;
  }
}

.timingbtn.active{
  background-color: white!important;
  color: black!important;
}

.questiontitle {
  font-size: 17px;
  font-weight: 600;
}

.treatmentnote-wrapper label.radio-inline input {
  margin-right: 6px;
}

.treatmentnote-wrapper label.radio-inline {
  margin-right: 14px;
  font-weight:400!important;
}

.treatmentnote-wrapper label.checkbox-inline input {
  margin-right: 6px;
}

.treatmentnote-wrapper label.checkbox-inline {
  margin-right: 14px;
  font-weight:400!important;
}

.treatmentnote-wrapper .image-wrapper {
  width: fit-content;
  padding: 10px;
  border-radius: 4px;
  margin-top: 30px;
}

.treatmentnote-wrapper .image-wrapper img {width: 250px;height: auto;}

.treatmentnote-wrapper .image-wrapper i.fa.fa-trash {
  float: right;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  box-shadow: 2px 2px #d2d2d2;
  font-size: 16px;
  padding-top: 6px;
  padding-left: 5px;
}


.prescriptionletter-wrapper .card-body .table {
  margin-bottom: 0;
  border: 1px solid #f0f0f0 !important;
  box-shadow: 0 0 8px 1px #00000021;
  border-radius: 4px;
}

.prescriptionletter-wrapper .card-body .col-md-12 .row .col-md-6:first-child {
  border: 1px solid #f0f0f0 !important;
  box-shadow: 0 0 8px 1px #00000021;
  border-radius: 4px;
}

.treamentnote-view-wrapper .question-answer-wrapper{
  margin : 20px 0;
  font-size: 110%;
}

.treamentnote-view-wrapper .question{
  font-weight : 600;
}

.treamentnote-view-wrapper .image-wrapper img {width: 250px;height: auto;}

.treatmentnote-user-title h2 {
  margin-bottom: 20px;
  font-size: 33px;
  text-transform: capitalize;
  font-weight: 600;
}

.treatmentnote-template-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.green {
  background-color: green !important;
  color: #ffffff !important;
}

.amber{
  background-color: #FFBF00 !important;
  color: #000 !important;
}

.red{
  background-color: red !important;
  color: #000 !important;
}

.blue{
  background-color: blue !important;
  color: #ffffff !important;
}

.white-link{
  color: #fff;
}

.white-link:hover{
  opacity: 0.7;
  color: #fff;
}

.kit-activation-page{
  background-color: #e9ecef;
}

.usertype{
  background-color: rgba(0,0,0,.03);
}

.msg-success ul{
  list-style-type: none;
  padding-inline-start: 0;
  margin-top: revert;
}

.msg-success a{
  color: #007bff;
}
.user-form span.select2-container
{
  height: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display{
  padding-left: 20px !important;
}
li.select2-selection__choice
{
  color: black !important;
  padding: 2px 10px !important;
}
.alert.alert-success.text-center.msg-success {
  margin: 0 20px;
  padding:0;
}

@media print {
  h3.card-title, .prnt_btn, .bs-stepper .col-md-2.card, .view-report, .btn-submit, .invoice-footer, .btn, .fa-edit, .dataTables_filter, .dataTables_info, .dataTables_paginate, .paging_simple_numbers, .dataTables_length {
    display: none !important;
  }

  .bs-stepper .col-md-10.card{
    border: 1px solid lightgrey !important;
    margin: 0 auto !important;
  }

  .medical_title h2{
    font-size: 44px !important;
  }

  .medical_title h3{
    font-size: 34px !important;
  }

  .medical_title h4{
    font-size: 31px !important;
  }

  .med_title h3{
    font-size: 20px !important;
  }

  table.client_tbl>tbody>tr>th{
    font-weight: bold !important;
    font-size: 20px !important;
  }

  table.client_tbl>tbody>tr>td{
    font-size: 19px !important;
  }

  ul.cmt li{
    font-size: 19px !important;
    margin: 25px 0px !important;
  }

  .report{
    border: 1px solid lightgrey!important;
  }

  .metlgy p{
    font-size: 20px !important;
  }

  #printsection{
    -webkit-print-color-adjust: exact;
  }


/*  div,table,ul
  {
    margin: 4px 0 4px 0;
    page-break-inside: avoid;
  }*/
}

.invoice_action_heading{
  color: #3e78d6;
  border-bottom: 2px solid #3e78d6;
  padding-bottom: 10px;
}

.invoice_view_row_1{
  margin-bottom: 25px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 25px;
}

.invoice_view_row_4{
  margin: 0;
  margin-top: 25px;
  padding-top: 60px;
  border-top: 1px solid lightgrey;
}

.invoice_view_row_5{
  margin-top: 50px;
}

.invoice_view_row_5 .text-right{
  padding-right: 20px;
}

.invoice_view_table1,
.invoice_view_table2{
  width : 100%;
}

.invoice_view_table1 thead tr{
  border-bottom: 1px solid lightgrey;
}

.invoice_view_table1 thead tr th,
.invoice_view_table1 tbody tr td{
  padding : 5px 0;
}
/** POS **/
.base-blue {
  background-color: #007bff;
  padding: 0;
}

a.nav-a.nav-link:hover {
  color: darkslategrey;
  font-weight: 700;
}

.nav-a.nav-link.active {
  background: #fff;
  color: dimgrey;
  font-weight: 700;
  border-radius: 0;
  padding: 2rem;
}

.non-active-pills li.nav-item a {
  color: white;
  padding: 0;
  margin: 2rem 1rem;
}

.non-active-pills li.nav-item a.active {
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff1c;
}

.non-active-pills .nav-link:not(.active):hover {
  color: #fff;
}

.non-active-pills a.nav-a.nav-link:hover {
  color: #fff;
}

.nav-a.nav-link {
  color: #fff;
  padding: 2rem;
}

.base-green.fa.fa-plus {
  background-color: #42b742;
  color: #fff;
  padding: 6px;
  text-align: center;
  width: 33.3333333333%;
}

.base-red {
  background-color: #ed3737;
  color: #fff;
  padding: 6px;
  text-align: center;
  width: 33.3333333333%;
}

.base-yellow {
  background-color: #eddf37;
  color: #fff;
  padding: 2px;
  text-align: center;
  width: 33.3333333333%;
  font-weight:700;
}
.info-panel{
  background-color: #fff;
  padding: 5px;
}

.color-blue p{
  color: #007bff;
  font-weight: 700;
}
.base-r{
  background-color: #ed3737;
  color: #fff;
}

.base-purple{
  background-color: #3b81d9;
  color: #fff;
  border-top: 4px solid #1d569d;
}

.base-r, .base-purple p{
  font-weight: 700;
  margin-bottom: 0;
  padding: 3px;
}

button.info-pay-btn {
  border: none;
  color: #fff;
  background: #15bbb3;
  width: 100%;
  padding: 7px;
  margin-top: 6px;
  font-weight: 700;
}
button.info-reset-btn {
  border: none;
  color: #fff;
  background: #cf0d0d;
  width: 100%;
  padding: 7px;
  margin-top: 6px;
  font-weight: 700;
}

.base-dark{
  background: #2e2f32;
}

.cat-search{
  background: transparent;
  border: 1px solid #fcfcfc59;
  margin: 10px 0;
  width: 55%;
  padding: 6px;
  color: #fff;
  font-weight: 600;
}

.card.info-card {
  background: #007bff;
  border: none;
  cursor: pointer;
}

p.info-namesection-1,
p.info-namesection-3 {
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

p.info-namesection-2 {
  background: #3498db;
  color: #fff;
  text-align: center;
  height: 200px;
  margin: 0;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.info-p{
  font-weight: 700;
}

.color-light-b{
  background-color: #15bbb3;
  color: #fff;
  font-weight: 700;
  padding: 1px;
  margin-bottom: 10px;
}

.price-panel{
  margin-top: 6px;
  padding-top: 6px;
  border-top: 2px solid #007bff;
}

.minivideo{
  position: fixed;
  top: 0;
  right: 5px;
  z-index: 999;
}
body{
  margin: 0;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
app-appointments-calendar .ngb-dp-day[tabindex="0"] div{
  background-color:#007bff!important;
  color:white;
}

.hn-calendar-item
{
  border-top: solid 1px #ccc;
  background-color:  #008DC8;
  color: white;
}
.hn-doc-list
{
  background-color: #f4f2f2;
}
.pos-label{    background-color: #3498db;
  opacity: 0.8;}

.fc-col-header
{
  background-color: antiquewhite;
  color: black;
}

.example-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.hn-doc-list:last-child {
  border: none;
}

.hn-doc-list.cdk-drop-list-dragging .hn-doc-list:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}



.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: calc(2.25rem + 2px);
	width: 100%;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #c7cace;
	border-radius: 4px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}

.content-wrapper {
  position: absolute;
  margin-top: 0;
  margin-left: 0px !important;
  width: 100%;
}

.main-sidebar,
.main-sidebar::before {
  width: 100% !important;
}

.nav-sidebar .nav-link p {
  white-space: unset !important;
}

.sidebar-collapse .sidebar {
  z-index: -1;
  position: relative;
  overflow: hidden !important;
}

.sidebar-collapse .main-sidebar{
  margin-left: 0 !important;
}

.sidebar-closed .content-wrapper {
  margin-top: 0;
}

.modal-dialog.modal-lg {
  margin-top: 7rem;
}

body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .main-header,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
  margin-left: 0 !important;
}

.progress-loader {
  margin-left: 0 !important;
}

.sidebar nav {
  transition: all 0.5s ease;
}

.sidebar-collapse .sidebar nav {
  margin-left: 100%;
  transition: all 0.5s ease;
}

.sidebar nav ul li .nav-link {
  font-size: 15px;
  width: max-content !important;
}

ul.navbar-nav li .nav-link {
  font-size: 15px;
  width: max-content;
}

.pro_nav_img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.display-5{
  font-size: 20px;
}

.info-namesection-2 span {
  background-color: #0e0e0e94;
  padding: 5px 15px;
  border-radius: 3px;
}

@media screen and (max-width: 991px) {
    .sidebar-collapse .sidebar {
      margin-top: -110px;
    }

    .navbar-nav .user-menu {
      display: none;
    }
}

@media screen and (max-width: 480px) {
  .sidebar nav {
      position: absolute;
      padding: 0 1rem;
      left: 0;
      top: 4.5rem;
      background-color: #006089;
      width: 100%;
    }

    .sidebar-open .nav-sidebar {
      flex-direction: column !important;
    }

    .sidebar-collapse .sidebar nav {
      margin-bottom: 100%;
      margin-left: 0;
      transition: all 0.5s ease;
    }
}

.btn-check {
  margin: 4px;
  background-color: #EFEFEF;
  border-radius: 4px;
  overflow: auto;
  float: left;
}

.btn-check:hover {
  background: #008dc8;
  color: #FFF;
}

.btn-check label {
  float: left;
  width: 100%;
  cursor: pointer;
}

.btn-check label span {
  text-align: center;
  padding: 3px 0px;
  display: block;
  width: 6rem;
}

.btn-check label input {
  position: absolute;
  top: -20px;
  display: none;
}

.btn-check input:checked+span {
  background-color: #008dc8;
  color: #fff;
}

.check-table:first-child{
  margin-top: 4rem;
}

.check-table {
  position: relative;
  margin-top: 1rem;
}

.btn-check-label {
  margin: 0;
}

.template_signature {
  all: unset !important;
}

.signature_image {
  width: 25rem;
  border: 1px solid grey;
}

.treatmentnote-wrapper .image-wrapper {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.image-wrapper a {
  box-shadow: 0 0 4px 1px #00000033;
  border: 1px solid #00000038;
}

.share_form{
  margin: 3.5% 7%;
  border-radius: 5px;
  background: #fff !important;
  box-shadow: 0 0 12px #329edd !important;
}

.share_form h4 {
  color: #171717;
  font-weight: 600;
  border-bottom: 2px solid #171717;
  padding: 0 0 12px;
  margin: 1rem 0 2rem 0;
  text-transform: uppercase;
}

.ifrequired {
  margin-left: 1rem;
}

.question_sub_title {
  color: #329edd;
  padding-bottom: 5px;
}

.pos-check label span {
    width: 2rem;
    margin: 0;
}

.pos-check{
	margin-bottom: 0.5rem;
}

.cdk-drag-handle svg {
  position: absolute;
  left: 0;
  margin-top: 2.3rem;
  height: 20px;
  z-index: 1;
  display: none;
}

.cdk-drag:hover .row .cdk-drag-handle svg {
  display: block;
  cursor: grabbing;
  fill: #adadad;
  margin-top: 2.4rem;
}

.dt-button.buttons-excel.buttons-html5 {
  background: none;
  background-color: #008dc8 !important;
  border: none !important;
  color: #fff;
  font-size: 15px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  margin-left: 1rem;
}

.dt-button.buttons-excel.buttons-html5 i.fas.fa-file-export {
  font-size: 12px;
  padding-left: 5px;
}

.chat-container {
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 9999;
  padding: 1rem;
  border-radius: 5px;
}

.chat-icon {
  color: #FFF;
  cursor: pointer;
  background-color: #00658f;
  padding: 11px 17px;
  border-radius: 5px;
  font-size: 18px;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 99999;
}

.chat-on {
  width: 430px;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 9999;
  background-color: #004460;
  padding: 1rem 1rem 4rem;
  border-radius: 5px;
}

.chat-avatar {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 5px;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.chat-input-box {
  width: 100%;
  padding: 11px;
  border: none;
  box-shadow: 0px -4px 4px 2px #0000000d;
}

.chat-input {
  display: flex;
  gap: 1rem;
}

.chat-area {
  max-height: 250px;
  overflow-y: scroll;
}

.chat-area::-webkit-scrollbar {
  width: 5px;
}

.chat-area::-webkit-scrollbar-thumb {
  background: #1b5685;
  border-radius: 10px;
}

.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.chat-input-box:focus {
  outline: none;
}

.chat-btn {
  border: none;
  color: #FFF;
  background-color: #008dc8;
  padding: 0 15px;
  border-radius: 5px;
}

.chat {
  background-color: #FFF;
  padding: 15px;
  border-radius: 5px;
}

.chat-head {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.sender {
  margin: 0;
  padding-left: 1.5rem;
  font-weight: 700;
  font-size: 16px;
}

.receiver {
  margin-bottom: 0;
  padding-left: 1.5rem;
  font-weight: 500;
  color: #898989;
}

.online-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  cursor: pointer;
  margin-bottom: 15px;
}

.online-user img {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 5px;
}

.online-chatter {
  font-weight: 600;
  margin-bottom: 0;
}

.history-chat {
  margin-bottom: 0;
  font-size: 13px;
  color: #767676;
}

.chat-list {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.user-list {
  border: none;
  border-radius: 5px;
  padding: 5px 11px;
  background-color: #ffffff29;
  color: #FFF;
  font-size: 15px;
}

.back-list {
  border: none;
  border-radius: 5px;
  padding: 3px 13px;
  background-color: #ffffff29;
  color: #FFF;
  font-size: 18px;
}

.chat-list p {
  margin-bottom: 0;
  padding-left: 1.5rem;
  font-weight: 600;
  color: #FFF;
}

.message-text {
  word-break: break-all;
}

.user-message .message-text {
  color: #fff;
  padding: 10px 20px;
  background-color: #1b5685;
  border-radius: 30px 5px 5px 30px;
}

.sender-message .message-text {
  color: #FFF;
  width: fit-content;
  padding: 10px 20px;
  background-color: #353f46;
  border-radius: 5px 30px 30px 5px;
}

.sender-chat-messages {
  display: flex;
  flex-direction: column;
  place-items: start;
  padding-right: 5px;
}

.user-message {
  margin: 0 0 0 auto;
}

.online-list {
  overflow-y: scroll;
  max-height: 200px;
}

.online-list::-webkit-scrollbar-thumb {
  background: #1b5685;
  border-radius: 10px;
}

.online-list::-webkit-scrollbar {
  width: 5px;
}

.user-message .chat-time {
  text-align: right;
}

.chat-time {
  color: #5f6368;
  font-size: 13px;
}

.chat-notification {
  width: 25px;
  height: 25px;
  padding: 3px;
  color: #FFF;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff5656;
}

.wizard-form .btn.btn-primary {
  float: right;
}

.modal-skip-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent !important;
}

.edit-wizard {
  float: left !important;
  margin-right: 10px;
}

.wizard-back {
  float: left !important;
  margin-left: 1.2rem;
}

.wizard-back-edit {
  float: left !important;
  margin-left: 1.2rem;
  margin-block-start: -4%;
}

.wizard-back-end {
  float: left !important;
  margin-left: 1.2rem;
  margin-block-start: -4%;
  margin-block-start: -85px;
}

button.btn.btn-primary.mr-4.wizard-end {
  position: absolute;
  bottom: 6.5%;
  left: 3.5%;
}

.site-logo-img {
  width: 15rem;
}